import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = 'users'
const Actions = crudActions(endpoint)

const getMe = () => axios.get(`${endpoint}/me/permissions`)
const getUsersList = () => axios.get(`${endpoint}`)

const getActiveUsersList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  queryParams = { ...queryParams, ...{ status: 'active' } }
  axios.get(`/${endpoint}`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const getInActiveUsersList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  queryParams = { ...queryParams, ...{ status: 'inactive' } }
  axios.get(`/${endpoint}`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
const setActive = (ctx, data) => new Promise((resolve, reject) => {
  data.status = 'active'
  axios.put(`/${endpoint}/${data.id}`, data)
    .then(response => {
      resolve(response)
    }).catch(error => {
      console.log(error)
      reject(error)
    })
})
const setInActive = (ctx, data) => new Promise((resolve, reject) => {
  data.status = 'inactive'
  axios.put(`/${endpoint}/${data.id}`, data)
    .then(response => {
      resolve(response)
    }).catch(error => {
      console.log(error)
      reject(error)
    })
})
const updateUser = (ctx, data) => axios.post(`/${endpoint}/${data.get('id')}?_method=PUT`, data)
const createUser = (ctx, data) => axios.post(`/${endpoint}`, data)

const update = (ctx, data) => axios.put(`/${endpoint}/${data.id}`, data)
export default {
  ...Actions,
  getMe,
  getActiveUsersList,
  update,
  setActive,
  setInActive,
  getInActiveUsersList,
  getUsersList,
  updateUser,
  createUser,
}
