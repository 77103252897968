export default {
  GET(state, data) {
    state.user = { ...data }
  },
  LIST(state, data) {
    state.users = data
  },
  SET_USER_FORM(state, data) {
    state.userForm = { ...data }
  },
}
