<template>
  <sub-menu-component
    :menu-items="menuItems"
  >
    <router-view @updateMenu="updateMenu" />
  </sub-menu-component>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import userModule from '@/store/user'
import userMenuItems from '@/views/components/sub-menu/settings-sub-menu/users'

export default {
  name: 'Users',
  setup() {
    const menuItems = ref()
    const MODULE_NAME = 'users'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, userModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      menuItems,
    }
  },
  methods: {
    updateMenu(active) {
      this.menuItems = userMenuItems(active).menuItems
    },
  },
}
</script>

<style scoped>

</style>
